import './App.css';
import * as CONSTANTS from './constants'

function App(){
  return (
    <div className="App-body">
      <p className="App-warning"><code>{CONSTANTS.WARNING}</code></p>

      <h1>{CONSTANTS.IAM}</h1>

      <p>{CONSTANTS.ABOUT_ME}</p>
      <h3>{CONSTANTS.OFFER_RECEPTION}</h3>
      <h3>{CONSTANTS.CONTACTS}</h3>

      <h2>{CONSTANTS.TITLE_OFFER}</h2>
      <p>{CONSTANTS.OFFER_LINE_START}</p>
      <p>{CONSTANTS.OFFER_LINE_END}</p>
      <hr />
      <p>{CONSTANTS.THERAPY}</p>
      <ul>{
          CONSTANTS.THERAPY_LIST.map(str => (<li>{str}</li>))
      }</ul>
      <p>{CONSTANTS.THERAPY_END}</p>

      <h2>{CONSTANTS.TITLE_HISTORY}</h2>
      <p>{CONSTANTS.HISTORY}</p>
      <p>{CONSTANTS.TECHNOLOGY}</p>

    </div>
  );
}

export default App;
