export const IAM = 'Ваш остеопат - Людмила Хороша';

export const TELEFON = '+38 093 60 116 60';

export const PRICE1 = '800';
export const PRICE2 = '700';
export const PRICE3 = '600';

export const TITLE_HISTORY = 'Трохи про остеопатію';

export const HISTORY = `Засновник остеопатії Е. Т. Стілл. В далекому 1874 році остеопатія була запропонована світу як терапія яка розглядала тіло як цілосну систему, в противагу до медицини, яка лікує окремі частини тіла. І одне лікує інше руйнує
У. Г. Сатерленд - учень доктора Стілла доповнив і розвинув краніосакральний напрямок остеопатії. 
Багато відомих людей доповнювали та розширювали остеопатію цю дуже впливову терапію на протязі майже 150 років. Так! 22 червня 2024 році остеопатії виповниться 150! Серед відомих людей: М. Літтлджон, Г. І. Магун, Д. Апледжер, М. Керн, Ж.П.Барраль, А. Беккер, В. М. Фрайман та багато інших заслужених діячів з різних країн. 
Остеопат розглядає тіло як живу цілосну систему, де всі частини організму залежать одна від одної, де нерв, артерія та вєна та інші важливі живильні рідини повинні бути проточними та вільними. Де функція ( рух ) формує якість тканин тіла, і навпаки, де якість наших м'язів та інших органів впливає на функціональність організму. В нашому тілі заложений дуже великий потенціал самовідновлення та зцілення.`;

export const TECHNOLOGY = `Остеопат збирає данні про захворювання пацієнта. Проводить різного роду тести. Аналізує і робить висновки, що стало першопричиною захворювання, і які проблеми з'явилися як адаптація до тих перших дисфункцій. Знайти ці дисфункції та усунути їх, за допомогою різних прийомів за допомогою чутливих рук остеопата. Остеопат працює руками використовуючи різні техніки, але це безболісні та дієві прийоми які запускають самовідновлення організму, де сам організм стає головним героєм, який знову має сили для самозцілення. 
Багато з нас не задумувалися або це для нас стало типовим і звичним, що поріз на пальці загоюються сам, що, якщо не лікувати насморк то, як правило, він проходить сам, якщо упав, то поболів бік, потім пройшло, це все працюють системи відновлення, фізика та хімія тіла, які у нас з нами від народження. Але бувають падіння, удари, або затяжні хвороби, з якими організм не може розібратися сам до кінця, вони занадто великі для нього. Тому організм загоює та адаптується на скільки на скільки може, буває так, що ця адаптація доволі вдала, а буває, що через ці адаптації починають страждати інші органи та системи, і відбуваються вже патологічні зміни в організмі. Чим раніше людина звертається з існуючою проблемою( дисфункцією), тим легше допомогти. Корисно до остеопата звертатися для профілактики раз на пів року, тоді можна попередити ще не розвинуті проблеми. Остеопат працює з дітьми від народження. З дітками остеопату працювати легше, тільце молоде і швидше проходить оновлення та проходять порушення.`;

export const TITLE_ABOUT_ME = 'Про себе';
export const ABOUT_ME = `Остеопат з трьох річним стажем. Велика подяка Школі "Шано". Де вчителі з рук в руки, від серця до серця передавали ці знання, вклали повагу та любов до цієї професії, де знання анатомії, фізіології, хімії тіла та методи дидукції та чутливі руки - це ті дієві методи, якими я користуюсь.`;

export const TITLE_THERAPY = `Запрошую на сеанси терапії`

export const THERAPY = `Люди приходять з різними проблемами, 
з того що я бачу, то можу зробити висновки, що остеопатія може допомогти:`;

export const THERAPY_LIST = [
  'головні болі, різного походження,',
  'сколіоз(як правило, це адаптація до інших проблем в організмі),',
  'оніміння в руках, кінцівках та болі в шиї,',
  'болі між лопатками,',
  'болі в поперековому відділі, коліна, стопи,',
  'протрузії, грижі різного роду,',
  'проблеми з диханням, бронхи та часті простуди,',
  'нервова збудженість та панічні атаки,',
  'слабкість та апатія,',
  'стріляючи болі (можуть бути будь де),',
  'вісцеральні проблеми(органи брюшини),',
  'та інші проблеми.'];

export const THERAPY_END = `Остеопат це не лікар, він не "лікує". 
Відкриті переломи, свіжі порізи, рак, інфекційні хвороби в розгорнутій стадії,
лихоманка з температурою 38 та більше, нестабільність суглобів це не до остеопата.
Остеопат йде поруч з лікарем в особливо важких ситуаціях і не може відміняти
те що призначив лікар.
Але виходячи з основних положень остеопатії,
гарно обучений остеопат може допомогти тілу знайти ті сили відновлення,
що допоможуть зцілити хвороби від яких вже відмовляються лікарі.
Тому остеопатичну корекція, адаптація організму, підібрані спортивні вправи,
та в деяких випадках правильно сформована програма харчування, можуть творити,
в прямому сенсі, чудеса.`;

export const TITLE_OFFER = 'Запрошую на сеанси остеопатії та терапії';
export const OFFER_RECEPTION = 'Прийом з 10 до 18 з пн-пт, Київ (Позняки)';
export const OFFER_LINE_START = `Перший прийом для дорослих ${PRICE1} гр, наступні по ${PRICE2} гр. Дитячий прийом перший ${PRICE2} наступні ${PRICE3} гр. 
Для того щоб досягти результатів зазвичай проводжу близько 3-х сеансів на протязі 1-1,5 місяця. Покращення відчувається, як правило, вже з першого сеансу. Після 2-3 сеансів, вже більш зрозуміло, як далі формувати программу, як правило, пацієнт приходить у вільному режимі через 1-3 міс. або пізніше, дивиться по потребі.`;
export const OFFER_LINE_END = `Для військових, що проходять реабілітацію та лікування у Києві проводжу сеанси за невеликий донейшн по четвергам.`;
export const CONTACTS = `Запис та консультація - Viber, Telegram за номером : ${TELEFON}.`;

export const WARNING = 'Cайт знаходиться у розробці';